import styled from 'styled-components'
import { font } from 'styles/typography'

export const Wrapper = styled.main`
  padding: 0 0 5rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2em;
    font-family: ${font.headings};
  }

  ul,
  ol {
    padding-inline-start: 4rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    list-style-type: unset;
  }

  li > ul,
  li > p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`
