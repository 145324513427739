import React, { FC } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import Container from 'components/container'
import SEO from 'components/seo'
import { Wrapper } from './styled'

type Props = {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        title: string
      }
    }
  }
}

const ContentPage: FC<Props> = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.markdownRemark.frontmatter.title} />
      <Container>
        <Wrapper>
          <h1>{data.markdownRemark.frontmatter.title}</h1>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: data.markdownRemark.html,
            }}
          />
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default ContentPage

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
